﻿#side-pop-up {
	display: none;

	&:checked + .side-pop-up-toggler {
		@extend .is-active;
	}

	&:checked ~ .side-pop-up {
		transform: translateX(0);
		opacity: 1;
		visibility: visible;
	}

	&:checked + #hamburger 
	{
		opacity: 1;
		visibility: visible;
	}
}

.side-pop-up {
	position: fixed;
	z-index: 1000;
	width: rem(320px);
	height: 100vh;
	top: 0;
	right: 0;
	opacity: 0;
	visibility: hidden;
	color: $color-red;
	background-color: rgba(#000, 0.5);
	transition: all 0.3s;
}

.side-pop-up__container {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	height: 100%;
	list-style: none;
	padding: 0;
}

.side-pop-up__content {
	@include font-nav-side;
	position: relative;
	z-index: 2;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	min-width: rem(480px);
	padding: rem(120px) rem(80px) rem(86px);
	background: $color-red;

	.logo {
		display: none;

		@include breakpoint(l) {
			display: block;
		}
	}
	ul { position: relative; }
	.navigation li {
		position: relative;
		display: flex;

		&:hover {
			background-color: #c9505e;
		}

		a {
			padding: 10px 0 10px 0;
			width: 100%;
		}
	}

	.navigation li:after, .navigation:after {
		position: absolute;
		background-color: rgba(#000, 0.15);
		width: 100%;
		height: 1px;
		border: 0;
		content: '';
	}

	.languages::selection {
		color: none;
		background: none;
	}

	.languages {
		display: flex;
		color: black;
		z-index: 1;
		padding: 5px;
		margin-top: 15px;

		dt:focus {
			outline: 0;
		}

		a {
			position: relative;
			display: block;
			padding: 10px 20px;

			&:hover {
				background-color: #e9e9e8;
			}
		}

		li:last-child a {
			border-bottom-left-radius: 24px;
			border-bottom-right-radius: 24px;
		}

		dt {
			z-index: 3;
		}

		dt label {
			@include font-lang;
			position: absolute;
			width: 20rem;
			margin-left: -5px;
			padding-left: 45px;
			white-space: nowrap;
			cursor: pointer;
			outline: none !important;
			background: white;
			border-radius: 25px;
			padding-top: 5px;
			padding-bottom: 5px;

			&:before {
				position: absolute;
				z-index: 1;
				left: 5%;
				top: 30%;
				visibility: visible;
				content: '';
				width: 24px;
				height: 15px;
				display: inline-block;
			}

			&:after {
				@include icon($icon-chevron-down);
				position: absolute;
				left: 93%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: black;
				margin-top: 17px;
				font-size: 10px;
			}
		}

		dd {
			position: absolute;
			font-size: 1em;
			font-weight: 400;
			display: none;

			ul {
				@include font-lang;
				display: flex;
				flex-direction: column;
				background-color: #ffffff;
				list-style: none;
				width: rem(320px);
				border-radius: 24px;
				border-top-left-radius: unset;
				border-top-right-radius: unset;
				margin-top: rem(20px);
				margin-left: -5px;

				li {
					padding: 0;
				}

				li:first-child {
					padding-top: 20px;
				}
			}
		}
	}

	#language-sidebar {
		display: none;
	}

	#language-sidebar:checked + .languages dd {
		display: block;
	}

	#language-sidebar:checked + .languages dt label {
		&:after {
			@include icon($icon-chevron-up);
		}
	}
}
