﻿.footer-content {
	width: 100%;

	@media screen and (max-width:1000px) {
		padding-bottom: rem(56px);
	}

	.footer-buttons {
		background-color: $color-green;
		max-width: 100%;

		li {
			margin: rem(72px) rem(20px) rem(72px) 0;
		}

		@media screen and (max-width:1024px) {
			li {
				margin: rem(19px) rem(20px) rem(19px) 0;
			}
		}

		@media screen and (max-width:480px) {
			li:first-child {
				//margin-top: 72px !important;
			}
		}

		.button {
			max-height: rem(48px);
		}
	}

	.footer-links {
		background-color: $color-green-2;
	}

	.footer-links li {
		@include font-links;
		padding: rem(10px) rem(25px) rem(10px) 0;
		list-style-type: none;
	}

	.nederlands-developer a, .dutch-developer a {
		visibility: hidden;

		&:before {
			content: "Een Panorama Studios website";
			visibility: visible;
		}
	}

	.french-developer a {
		visibility: hidden;

		&:before {
			content: "Un site web de Panorama Studios";
			visibility: visible;
		}
	}

	.german-developer a {
		visibility: hidden;

		&:before {
			content: "Eine Panorama Studios Website";
			visibility: visible;
		}
	}

	.container {
		display: flex;
		flex-wrap: wrap;
		padding-top: 0;
		padding-bottom: 0;
	}

	@media screen and (max-width:768px) {
		.container {
			flex-direction: column;
			align-items: center;

			.button:first-child {
				margin-top: rem(50px) !important;
			}

			li:first-child {
				margin-top: rem(15px) !important;
			}

			li:last-child {
				margin-bottom: rem(15px) !important;
			}
		}
	}

	.slogan {
		@include font-slogan;
		margin: -30px 0 0 0 !important;
		position: absolute;
		left: 60%;
		display: flex;
		list-style-type: none;
		align-items: center;
		justify-content: center;
		background: url(../../images/spot.png);
		background-size: cover;
		background-position: center;
		width: rem(500px);
		height: rem(250px);

		@media screen and (max-width:1300px) {
			position: relative;
			left: unset;
		}

		@media screen and (max-width:480px) {
			width: 100%;
		}
	}

	.footer-scroll-up {
		position: absolute;
		z-index: 1;
		display: block;
		width: 3.5rem;
		height: 3.5rem;
		border-bottom-left-radius: 50%;
		border-bottom-right-radius: 50%;
		left: 90%;
		transform: translateX(-50%);
		background: #859748;
		text-indent: -9999px;
		overflow: hidden;
		font-size: 12px;

		&:hover {
			background-color: lighten(#859748, 10%);
		}

		&:before {
			@include icon($icon-chevron-up);
			position: absolute;
			left: 50%;
			top: 55%;
			transform: translate(-50%, -50%);
			text-indent: 0;
			font-weight: 900;
			color: #434e20;
		}
	}
}
