﻿.umbraco-forms-field {

	input[type=text], input[type=email] {
		height: rem(50px);
		border-radius: rem(25px);
	}

	input[type=checkbox] {
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		width: 20px !important;
		height: 20px;
		outline: none !important;
		border-radius: unset;
		background-color: white;
		border-radius: none !important;
		border: none !important;
		margin-bottom: -3px;

		&:checked {
			background-color: #3176fe !important;

			&:before {
				content: '\2713';
				display: block;
				text-align: center;
				color: $color-default;
				position: absolute;
				left: 0.5rem;
				font-size: 16px;
				transform: translate(0, -15%);
			}
		}
	}

	.textarea {
		height: rem(112px);
		border-radius: rem(25px);
	}
}
