﻿.breadcrumb-container {
	position: relative;
	margin-bottom: rem(-40px);
	z-index: 3;
	opacity: 0.6;

	&.is-red {
		background-color: $color-red;
		color: $color-default;

		a:after {
			color: $color-default;
		}
	}

	&.is-white {
		background-color: $color-default;
		color: $color-green;

		a:after {
			color: $color-green;
		}
	}

	&.is-green {
		background-color: $color-teal;
		color: $color-green;

		a:after {
			color: $color-green;
		}
	}
}

.breadcrumb {
	.container {
		padding-top: 0;
		padding-bottom: 0;
	}

	ul {
		display: flex;

		li {
			@include font-breadcrumbs;
			list-style-type: none;

			a {
				position: relative;
				font-weight: lighter;
				padding-right: 30px;

				&:after {
					@include icon($icon-chevron-right);
					position: absolute;
					right: 17%;
					top: 40%;
					text-indent: 0;
					color: $color-default;
					font-size: 6px;
					font-weight: 700;
				}
			}
		}

		li:last-child {
			a:last-child {
				&:after {
					display: none !important;
				}
			}
		}
	}
}
