﻿.hero {
	position: relative;
	min-height: 160px;

	img {
		min-height: 350px;
		background-repeat: no-repeat;
		background-size: cover;
		object-fit: cover;
		background-color: $color-default;
	}

	figcaption {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		padding-top: 0;
		padding-bottom: 0;
	}

	&.empty-hero{
		background-color: $color-green;
	}
}
