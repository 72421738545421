﻿@import '~swiper/swiper';
@import '~swiper/components/navigation/navigation';
@import '~swiper/components/pagination/pagination';
@import '~swiper/components/effect-fade/effect-fade';

//swiper 
.swiper-pagination {
	bottom: rem(30px);
	right: 50%;
	transform: translateY(-50%);

	.swiper-pagination-bullet {
		display: block;
		float: left;
		background: rgba($color-green-bullet, 0);
		width: rem(10px);
		height: rem(10px);
		opacity: 1;
		margin: rem(10px) rem(5px);
		border: 1px solid $color-green-bullet;

		&.swiper-pagination-bullet-active {
			background: $color-green-bullet-active;
			border: none;
		}
	}

	&.disabled{
		display: none;
	}
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
	visibility: hidden;
}

// slideshow swiper
.slideshow {
	.swiper-pagination {
		width: rem(10px);
		top: 45%;
		right: auto;
		bottom: auto;
		left: rem(40px);
		transform: translateY(-50%);

		@media screen and (max-width:1740px) {
			left: 95%;
		}

		.swiper-pagination-bullet {
			display: block;
			width: rem(13px);
			height: rem(13px);
			opacity: 1;
			margin: rem(5px) 0;
		}

		&.swiper-pagination-bullet-active {
			background: $color-green-bullet-active;
			border: none;
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		width: rem(60px);
		height: rem(48px);
		box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.3);
		background: #fff;

		&:hover {
			background: mix(#fff, #000, 90%);
		}
	}

	.swiper-button-prev {
		border-radius: 0 rem(24px) rem(24px) 0;
		left: 0;
	}

	.swiper-button-next {
		border-radius: rem(24px) 0 0 rem(24px);
		right: 0;
	}
}