﻿header figure {

}
.logo {
	position: absolute;
	width: rem(297px);
	height: rem(60px);
	display: block;
	background-size: contain;
	overflow: hidden;
	text-indent: -99999px;
	background-image: url(../../images/hoflor-logo.svg);
	background-repeat:no-repeat;
}

.logo-block {
	display: flex;
	align-items: center;
}