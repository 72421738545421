﻿.call-to-action {
	position: relative;

	img {
		min-height: 350px;
		background: $color-red;
		background-repeat: no-repeat;
		background-size: cover;
		object-fit: cover;
	}

	figcaption {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		padding-top: 0;
		padding-bottom: 0;
	}

	.button {
		margin-bottom: 0;
	}
}
