﻿.nav-container {
	@include font-nav;
	display: flex;
	flex-wrap: wrap;
	margin-left: auto;
	align-items: center;

	ul {
		display: flex;

		li {
			a {
				padding: rem(18px);
				display: block;

				&:hover {
					background-color: #e9e9e8;
				}
			}
		}
	}

	.languages::selection {
		color: none;
		background: none;
	}

	.languages {
		display: flex;
		width: 64px;
		z-index: 1;

		dt {
		}

		dt:focus {
			outline: 0;
		}

		a {
			position: relative;
			display: block;
			padding: 3px 20px;

			&:hover {
				background-color: #e9e9e8;
			}
		}

		dt label {
			position: absolute;
			z-index: 5;
			width: 70px;
			height: 31.5px;
			margin-top: rem(-18px);
			text-indent: 100%;
			white-space: nowrap;
			cursor: pointer;
			outline: none !important;
			background-color: white;
			border-top-left-radius: rem(7px);
			border-top-right-radius: rem(7px);

			&:before {
				position: absolute;
				z-index: 1;
				left: 20%;
				top: 30%;
				visibility: visible;
				content: '';
				width: 24px;
				height: 15px;
				display: inline-block;

				@media screen and (max-width: 1000px) {
					visibility: hidden;
				}
			}

			&:after {
				@include icon($icon-chevron-down);
				font-size: 8px;
				position: relative;
				right: 35%;
				visibility: visible;

				@media screen and (max-width: 1000px) {
					visibility: hidden;
				}
			}
		}

		dd {
			position: absolute;
			font-size: 1em;
			font-weight: 400;
			display: none;

			ul {
				@include font-lang;
				border: none;
				outline: none;
				display: flex;
				flex-direction: column;
				background-color: #ffffff;
				list-style: none;
				width: rem(155px);
				margin-left: rem(-85px);
				border-top-left-radius: rem(5px);
				border-bottom-left-radius: rem(5px);
				border-bottom-right-radius: rem(5px);
				margin-top: rem(13px);
				box-shadow: 0 0 10px 1px rgb(0 0 0 / 25%);

				&:after {
					position: absolute;
					content: "";
					width: rem(141px);
					height: 1%;
					z-index: 1;
				}

				li {
					padding: 0;

					a {
						align-items: center;
						display: flex;
					}
				}
			}
		}
	}

	#language {
		display: none;
	}

	#language:checked + .languages dd {
		display: block;
	}

	#language {
		&:checked + .languages label {
			box-shadow: -5px 0 5px -5px rgb(0 0 0 / 25%), 5px 0 5px -5px rgb(0 0 0 / 25%), 0 -5px 5px -5px rgb(0 0 0 / 25%);
		}
	}
}

.dutch-icon::before, .nederlands-icon::before {
	background: url(../../images/nl.png);
}
.french-icon::before {
	background: url(../../images/fr.png);
}
.german-icon::before {
	background: url(../../images/de.png);
}

// icons in dropdown
.nl a::before {
	content: '';
	background: url(../../images/nl.png);
	width: 24px;
	height: 15px;
	display: inline-block;
	margin-right: 5px;
}

.fr a::before {
	content: '';
	background: url(../../images/fr.png);
	width: 24px;
	height: 15px;
	display: inline-block;
	margin-right: 5px;
}

.de a::before {
	content: '';
	background: url(../../images/de.png);
	width: 24px;
	height: 15px;
	display: inline-block;
	margin-right: 5px;
}

.languages li:last-of-type a:after {
	content: none;
}