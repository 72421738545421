﻿@import "../mixins/breakpoints";
@import "../custom/variables";

// Gutter creation mixin
@mixin columns-gutter($size) {
	$size-half: ($size / 2 * 1px);

	.columns.has-gutter-#{$size} {
		margin-left: ($size-half * -1);
		margin-right: ($size-half * -1);

		.column {
			border-color: transparent;
			border-style: solid;
			border-top: 0;
			border-right-width: ($size-half);
			border-bottom: 0;
			border-left-width: ($size-half);
		}
	}
}

// Columns
.columns {
	display: flex;
	flex-wrap: wrap;

	&.has-no-wrap {
		overflow: hidden;
		flex-wrap: nowrap;
	}

	&.is-vertical-aligned {
		align-items: center;
	}
}

.column {
	flex: 0 0 auto;
	width: 100%;

	&.is-vertical-aligned {
		align-self: center;
	}

	&.is-left{
		order: 1;
	}

	&.is-right{
		order: 2;
	}
}

@mixin columns-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.columns.is-#{$size-name} {

			.column {
				width: $size;
			}
		}
	}
	@else {

		.columns.is-#{$size-name}-#{$breakpoint} {

			.column {
				@include breakpoint($breakpoint) {
					width: $size;
				}
			}
		}
	}
}

@mixin column-breakpoint($size-name, $size, $breakpoint) {

	@if($breakpoint == "b") {

		.column.is-#{$size-name} {
			width: $size;
		}
	}
	@else {

		.column.is-#{$size-name}-#{$breakpoint} {

			@include breakpoint($breakpoint) {
				width: $size;
			}
		}
	}
}