﻿@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Regular.woff2');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-Medium.woff2');
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@font-face {
	font-family: 'Montserrat';
	src: url('../../fonts/Montserrat-ExtraBold.woff2');
	font-weight: 800;
	font-style: normal;
	font-stretch: normal;
	font-display: block;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(20px);
	line-height: rem(32px);

	@media only screen and (min-width: 432px) {
		font-size: rem(19px);
	}

	@media only screen and (min-width: 600px) {
		font-size: rem(18px);
	}
}

@mixin font-lang {
	font-family: $font-text;
	font-size: rem(14px);
	font-weight: 400;
}

@mixin font-nav {
	font-family: $font-text;
	font-size: rem(16px);
	font-weight: 500;
}

@mixin font-nav-side {
	font-family: $font-text;
	color: $color-default;
	font-size: rem(24px);
	font-weight: 400;
}

@mixin font-button {
	font-family: $font-text;
	font-weight: 500;
	font-size: rem(18px);
}

@mixin font-links {
	color: $color-green-3;
	font-family: $font-text;
	font-weight: 500;
	font-size: rem(13px);
}

@mixin font-carousel-items {
	color: $color-green;
	font-family: $font-text;
	font-weight: 500;
	font-size: rem(18px);
	line-height: rem(40px);
	word-wrap: break-word;
	white-space: normal !important;
}

@mixin font-slideshow {
	font-family: $font-text;
	color: $color-green;
	line-height: rem(56px);
}

@mixin font-contact-block {
	font-family: $font-text;
	color: $color-default;
	line-height: rem(32px);
}

@mixin font-about-block {
	font-family: $font-text;
	color: $color-default;
	line-height: rem(32px);
}

@mixin font-slogan {
	font-family: $font-text;
	color: $color-green-3;
	font-weight: 500;
	font-size: rem(36px);
	letter-spacing: -1px;
}

@mixin font-breadcrumbs {
	font-family: $font-text;
	font-weight: 500;
	font-size: rem(14px);
}