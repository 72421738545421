﻿.contact-block {
	@include font-contact-block;
	background-color: $color-red;

	@media screen and (max-width:1024px) {
		.contact-information {
			padding-left: 0;
		}
	}

	.contact-information {
		padding-left: rem(70px);
	}

	.contact-form {
		padding-right: rem(70px);
	}
	/* form styling */
	form {
		input, textarea {
			width: 100%;
			border-radius: 20px;
			border: none;
			margin-bottom: rem(25px);
			padding-left: rem(20px);
		}

		input:focus, textarea:focus {
			outline: none;
		}

		input[type=checkbox] {
			width: auto;
		}

		button {
			color: $color-red;
			border: none;
			margin-top: 25px;
			margin-bottom: 25px;
		}
	}
}
