$icon-chevron-down: '\ea01';
$icon-chevron-left: '\ea02';
$icon-chevron-right: '\ea03';
$icon-chevron-up: '\ea04';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-chevron-down:before { content: $icon-chevron-down; }
.icon-chevron-left:before { content: $icon-chevron-left; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-chevron-up:before { content: $icon-chevron-up; }
