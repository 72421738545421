﻿@import "fonts";
@import "icon";


// Primary colors
$color-default: #ffffff;
$color-red: #ce0019;
$color-green: #778b33;
$color-green-2: #6d7f2f;
$color-green-3: #434e20;
$color-teal: #e8eeee;
$color-green-bullet-active: #a3b27d;
$color-green-bullet: #c4cfb4;

$transition-time: 0.5s;

// Base
$font-text: "Montserrat", sans-serif;
$line-height-p: rem(32px);
$margin-bottom-p: rem(56px);

// Container
$container-max-width: rem(1608px);
$container-small-max-width: rem(1056px);
$container-med-max-width: rem(1332px);
$container-large-max-width: rem(1608px);

// Grid
$grid-columns: 12;
$grid-gap: 3rem;