﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	img {
		width: 100%;
		height: auto;
	}

	&.is-left {
		order: 1;
	}

	&.is-right {
		order: 2;
	}
}
