﻿.content-block {
	position: relative;

	ul {
		margin: 0 0 rem(32px) rem(48px);
	}

	.column {
		transition: opacity $transition-time ease;
		width: 100%;

		&.is-hidden {
			opacity: 0;
		}
	}

	&.is-green,
	&.is-columns-block .column.is-green {
		background-color: $color-teal;

		p, h2, li {
			color: $color-green;
		}
	}

	&.is-red,
	&.is-columns-block .column.is-red {
		background-color: $color-red;

		p, h2, li {
			color: $color-default;
		}
	}

	&.is-white,
	&.is-columns-block .column.is-white {
		background-color: $color-default;

		p, h2, li {
			color: $color-green;
		}
	}

	.content-block__content, .container.small {
		p:last-of-type {
			margin-bottom: $margin-bottom-p;
		}
	}

	&.is-big-image {
		img {
			margin: 0 auto;
		}

		.buttons {
			padding: 0 0 rem(56px);

			.button {
				margin-bottom: 0;
			}
		}

		@media screen and (max-width:1024px) {
			.buttons {
				padding: 0 0 rem(36px);

				.button {
					margin-bottom: rem(20px);
				}
			}
		}
	}

	&.is-50-50 {
		text-align: center;

		.image {
			height: 100%;
		}

		.columns .content-block__content {
			max-height: 100%;
			margin: auto;
			padding: rem(160px) rem(156px) 0;
		}

		.buttons {
			padding: 0 0 rem(140px);

			.button {
				margin-bottom: rem(20px);
			}
		}

		@media screen and (max-width:1024px) {
			.columns .content-block__content {
				padding: rem(160px / 2) ($grid-gap / 2) 0;
			}

			.buttons {
				padding: 0 0 rem(60px);
			}
		}

		@media screen and (min-width:1024px) and (max-width:1500px) {
			.columns .content-block__content {
				padding: rem(160px / 2) ($grid-gap) 0;
			}

			.buttons {
				padding: 0 0 rem(60px);
			}
		}
	}

	&.is-small-image {
		.column {
			&.is-left {
				.content-block__content, .buttons {
					margin: 0 rem(48px) 0 0;
				}
			}

			&.is-right {
				.content-block__content, .buttons {
					margin: 0 0 0 rem(48px);
				}
			}
		}

		@media screen and (max-width:1024px) {
			.column {
				&.is-left {
					.content-block__content, .buttons {
						margin: 0;
					}
				}

				&.is-right {
					.content-block__content, .buttons {
						margin: 0;
					}
				}
			}
		}

		.container {
			padding-bottom: rem(140px);
		}

		.buttons {

			.button {
				margin-bottom: rem(20px);
			}
		}

		@media screen and (max-width:1024px) {
			.buttons {
				padding: 0 0 rem(36px);

				.button {
					margin-bottom: rem(20px);
				}
			}

			.container {
				padding-bottom: rem(160px / 2);
			}
		}
	}

	@media screen and (max-width:1024px) {
		.reverse-columns {
			flex-direction: column-reverse;
		}
	}

	&.is-album-block {
		text-align: center;

		.container {

			&.small {
				padding-bottom: 0;
			}

			&.large {
				padding-top: 0;
				display: flex;
				flex-wrap: wrap;
				padding-bottom: (rem(112px));

				@media screen and (max-width:1024px) {
					padding-bottom: rem(60px);
				}
			}
		}

		.column {
			margin-bottom: rem(48px);
		}
	}

	&.is-image-carousel-block {
		padding-bottom: rem(160px);

		@media screen and (max-width:1024px) {
			padding-bottom: (rem(160px) / 2);
		}

		h2 {
			margin-bottom: rem(26px);
		}

		figcaption {
			@include font-carousel-items;
			display: flex;
			max-width: 280px;
			margin: auto;
			justify-content: center;
			align-content: center;
			flex-direction: column;
			white-space: nowrap;
		}

		.container {
			&.small {
				padding-bottom: 0;
			}

			&.large {
				padding-top: 0;
				padding-bottom: 0;
				display: flex;
				flex-wrap: wrap;
			}
		}

		.swiper-button-prev {
			left: 0px;
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;

			&:before {
				@include icon($icon-chevron-left);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: #a2a6a6;
				font-size: 10px;
				font-weight: 900;
			}
		}

		.swiper-button-next {
			right: 0px;
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;

			&:before {
				@include icon($icon-chevron-right);
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				text-indent: 0;
				color: #a2a6a6;
				font-size: 10px;
				font-weight: 900;
			}
		}

		.swiper-button-prev, .swiper-button-next {
			top: 63%;
			z-index: 101;
			display: block;
			width: rem(56px);
			height: rem(56px);
			background: #e8eeee;
			text-indent: -9999px;
			overflow: hidden;

			&:hover {
				background-color: darken($color-teal, 5%);
			}
		}

		@media screen and (min-width:1024px) {
			.swiper-button-prev, .swiper-button-next {
				top: 60%;
			}
		}

		@media screen and (min-width:360px) and (max-width:480px) {
			.swiper-container {
				margin-left: rem(0px);
			}
		}

		.swiper-slide {
			width: auto;
			margin-right: 48px;
			text-align: center;
			background: #fff;
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
			flex-shrink: 0;
		}
	}
}
