﻿.button {
	@include font-button;
	position: relative;
	z-index: 0;
	display: inline-flex;
	padding: rem(8px) rem(40px) rem(8px) rem(40px);
	border-radius: 24px;
	margin: 0;
	text-decoration: none;
	overflow: hidden;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	box-shadow: none !important;

	&.is-white {
		background: $color-default;
		color: $color-red;

		&:hover {
			background-color: rgba($color-default,0.8);
		}
	}

	&.is-red {
		background: $color-red;
		color: #fff;

		&:hover {
			background-color: rgba($color-red,0.8);
		}
	}

	&.is-green {
		background: $color-green;
		color: #fff;

		&:hover {
			background-color: rgba($color-green,0.8);
		}
	}

	&.is-loading {
		padding-left: 60px;

		&:before {
			position: absolute;
			display: block;
			content: '';
			border: rem(4px) solid $color-red;
			border-radius: 50%;
			border-top: rem(4px) solid lighten($color-red, 30%);
			width: rem(20px);
			height: rem(20px);
			top: 50%;
			left: rem(16px);
			margin-top: -10px;
			animation: spin 1.5s linear infinite;
		}
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}
.content-block, .about-block, .call-to-action {
	.button {
		margin: 0 rem(20px) rem(55px) 0;
	}
}
.slideshow-slide-content {
	.button {
		margin: 0 rem(20px) rem(20px) 0;
	}
}