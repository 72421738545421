﻿.about-block {
	@include font-about-block;
	background-color: $color-red;
	background-image: url(../../images/about-map.png);
	background-repeat: no-repeat;
	background-position: right -330px bottom -70px;

	.about-column {
		padding-right: 0;
		padding-bottom: 700px;
		width: 100%;
	}

	@media screen and (min-width:1620px) {
		background-position: right 0 bottom 0px;
		background-size: unset;
	}

	@media screen and (min-width:1420px) {
		background-position: right -200px bottom 0px;
		background-size: contain;
	}

	@media screen and (min-width:420px) {
		background-position: right -250px bottom -70px;
	}

	@media screen and (min-width:710px) and (max-width:1420px) {
		.container {
			padding: 10rem 9.75rem 0;
		}

		.about-column {
			padding-bottom: 850px;
		}
	}

	@media screen and (max-width:1420px) {
		.container {
			margin: 0 auto;
			max-width: 69rem;
		}
	}

	@media screen and (min-width:1420px) {
		background-repeat: no-repeat;

		.about-column {
			padding-bottom: 0px;
			width: 50%;
		}
	}

	@media screen and (min-width:1420px) {
		.about-column {
			padding-right: rem(160px);
		}
	}

	.container {
		padding: rem(160px) ($grid-gap / 2);
	}
}
