﻿.slideshow {
	position: relative;
}

.slideshow-slide {
	overflow: hidden;
	min-height: rem(540px);
	height: calc(100vh - 7.5rem);

	figure{
		height:100%;
	}

	img {
		height: 100%;
		object-fit: cover;
		object-position: center;
		background-repeat:no-repeat;
	}
}

.slideshow-slide-content {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	transition: opacity 1s ease-in-out;

	.title {
		@include font-slideshow;
		max-width: rem(642px);
	}

	.button {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
	}

	.container {
		position: relative;
		max-width: 103.5rem;
		transform: translateY(-50%);
		top: 50%;
	}
}

.slideshow-images {
	height: inherit;
}

.slideshow-scroll-down {
	z-index: 1;
	position: absolute;
	display: block;
	width: rem(56px);
	height: rem(56px);
	border-top-left-radius: 50%;
	border-top-right-radius: 50%;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	background: #fff;
	text-indent: -9999px;
	overflow: hidden;
	font-size: 12px;

	&:hover {
		background-color: darken($color-default, 5%);
	}

	&:before {
		@include icon($icon-chevron-down);
		position: absolute;
		left: 50%;
		top: 55%;
		transform: translate(-50%, -50%);
		text-indent: 0;
		font-weight: 900;
		color: $color-red;
	}
}
